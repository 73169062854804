
































import { Vue, Component, Watch, PropSync } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { CFOP, CodigoEDescricao, Operacao } from '@/models'
import type { RegraPorEstado } from '@/models'
import SeletorDeCfop from '@/components/fiscal/SeletorDeCfop.vue'
import { FindCFOPUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		SeletorDeCfop,
	},
})
export default class CamposDeTipoDeOperacaoFiscal extends Vue {
	@PropSync('value') regraEstado!: RegraPorEstado
	obrigatorio = obrigatorio
	operacoes: Operacao[] = 
		[
			'SAIDA', 
			'DEVOLUCAO', 
			'REFERENCIADA', 
			'VENDA_BRINDE', 
			'DEVOLUCAO_BRINDE', 
			'CONSIGNACAO', 
			'DEVOLUCAO_CONSIGNACAO', 
			'TRANSFERENCIA', 
			'SAIDA_DEVOLUCAO',
			'VENDA_DEMONSTRACAO',
			'DEVOLUCAO_DEMONSTRACAO',
			'OUTROS_SAIDA',
			'OUTROS_ENTRADA',
			'VENDA_FORA_ESTABELECIMENTO',
			'SAIDA_CONSERTO',
			'RETORNO_CONSERTO',
			'ATIVO_IMOBILIZADO',
			'CONSUMO',
			'UNIFORME',
			'COMPLEMENTAR',
			'SAIDA_UNIFORME',
		]
		
	findCFOPUseCase = new FindCFOPUseCase()

	cfopPadrao: CFOP | null =  null
	cfopPadraoReferenciada: CFOP | null =  null
	
	async created() {
		try {
			[
				this.cfopPadrao,
				this.cfopPadraoReferenciada,
			] = await Promise.all([
				new FindCFOPUseCase().find({ codigo: '5102' }).then(cfops => cfops[0]),
				new FindCFOPUseCase().find({ codigo: '5929' }).then(cfops => cfops[0]),
			])
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get operacoesFiscais() {
		if (!this.regraEstado.tiposDeOperacoesFiscais) return []
		return this.regraEstado.tiposDeOperacoesFiscais
	}

	opcoesDeOperacoes(operacaoAtual: Operacao | null) {
		return operacaoAtual
			? [operacaoAtual, ...this.operacoesRestantes]
			: this.operacoesRestantes
	}

	get operacoesRestantes() {
		return this.operacoes.filter(op => !this.operacoesSelecionadas.includes(op))
	}

	get operacoesSelecionadas() {
		return this.regraEstado.tiposDeOperacoesFiscais
			.map(tipo => tipo.operacao)
	}

	@Watch('operacoesSelecionadas')
	async alteraCfopPadrao(){
		if(this.operacoesSelecionadas.includes('REFERENCIADA')) {
			this.cfopPadraoReferenciada = (await new FindCFOPUseCase().find({ codigo: '5929' }))
				.content[0]
		}
	}

	get temTodasAsOperacoes() {
		return this.operacoes.length === this.regraEstado.tiposDeOperacoesFiscais?.length
	}

	adicionaOperacao() {
		if (!this.regraEstado) return

		const operacao = this.operacoesRestantes[0]
		
		const cfop = operacao === 'REFERENCIADA'
			? this.cfopPadraoReferenciada
			: this.cfopPadrao
		
		this.regraEstado.tiposDeOperacoesFiscais.push({
			id: '',
			operacao,
			cfop: cfop as CodigoEDescricao,
		})
	}
}

